import React, { useEffect, useState } from "react";
import TextEditor from "./TextEditor";
import { useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners";

const EmailSender = () => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [subject, setSubject] = useState("");
  const [emails, setEmails] = useState(["jan@edgewatermc.com.au"]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchApplicants(token);
  }, []);

  const fetchApplicants = async (token) => {
    setLoading(true);
    try {
      const applicantsResponse = await fetch(
        "/api/admin/interviewed-applicants",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
        }
      );
      if (applicantsResponse.ok) {
        const applicantsData = await applicantsResponse.json();

        // Use setEmails correctly by updating the state with a new array
        setEmails((prevEmails) => [
          ...prevEmails,
          ...applicantsData.map((applicant) => applicant.email),
        ]);

        setLoading(false);
      } else {
        setLoading(false);
        if (applicantsResponse.statusText === "Unauthorized" && token) {
          handleLogout();
        }
        setError(applicantsResponse.statusText);
      }
    } catch (error) {
      setLoading(false);
      setError("An error occurred while fetching applicants.");
    }
  };
  const handleSend = () => {
    console.log("Subject:", subject, "message: ", message, "emails: ", emails);
    if (!subject || !message || !emails) {
      setError("Please complete all required information");
      return;
    }
    emails.forEach((email) => sendEmail(email, subject, message));
  };
  const sendEmail = async (email, subject, message) => {
    try {
      const response = await fetch(`api/admin/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({
          email,
          subject,
          message,
        }),
      });
      if (response.ok) {
        console.log(response);
      }
    } catch (error) {
      setError(error);
      console.error("Error:", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  if (loading) {
    return (
      <div>
        <FadeLoader
          color="#3f4540"
          cssOverride={{
            margin: "300px auto",
          }}
        />
      </div>
    );
  }
  return (
    <div className=" relative z-0 mt-[100px] max-w-[1000px] mx-auto w-full flex flex-col items-center">
      <div>
        <h1 className=" text-red-700 text-lg">{error}</h1>
      </div>
      <div className=" mx-3 text-center flex flex-col ">
        <label>Emails</label>
        <div className="flex gap-3 flex-wrap">
          {emails.length > 0 ? (
            emails.map((email, index) => (
              <div>
                {index + 1} - {email}
              </div>
            ))
          ) : (
            <div>No emails found</div>
          )}
        </div>
      </div>
      <label>Subject</label>
      <input
        type="text"
        required
        onChange={(e) => setSubject(e.target.value)}
      />
      <label>Message</label>
      <TextEditor setDesc={setMessage} />
      <button
        className=" cursor-pointer p-3 bg-blue-700 text-white rounded m-2"
        onClick={handleSend}
      >
        Send
      </button>
    </div>
  );
};

export default EmailSender;
