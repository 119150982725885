import React, { useState } from "react";
import InterviewUpdateModal from "./InterviewUpdateModal";
import {
  HandThumbDownIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";

function ApplicantsTable({ applicants }) {
  const [open, setOpen] = useState(false);
  const [targetApplicant, setTargetApplicant] = useState(null);
  const [interviewComment, setInterviewComment] = useState("");
  const token = localStorage.getItem("token");

  const conDate = (orgDate) => {
    const date = new Date(orgDate);

    const formattedDate = date.toLocaleDateString("en-AU"); // en-AU for DD/MM/YYYY format
    return formattedDate;
  };

  const accepted = async (applicantId, interviewComment) => {
    const proceed = true;
    try {
      const response = await fetch(
        `/api/applicants/${applicantId}/post-interview`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
          body: JSON.stringify({
            interviewComment,
            proceed,
          }),
        }
      );

      if (response.ok) {
        setInterviewComment("");
        window.history.go(0);
      } else {
        console.error("Failed to update interview");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const rejected = async (applicantId, interviewComment) => {
    const proceed = false;
    try {
      const response = await fetch(
        `/api/applicants/${applicantId}/post-interview`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            authorization: token,
          },
          body: JSON.stringify({
            interviewComment,
            proceed,
          }),
        }
      );

      if (response.ok) {
        setInterviewComment("");
        window.history.go(0);
      } else {
        console.error("Failed to update interview");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  return (
    <div className="mt-8">
      <InterviewUpdateModal
        open={open}
        setOpen={setOpen}
        applicant={targetApplicant}
      />
      {applicants.map((applicant, index) => (
        <div key={index} className="w-full flex flex-col border-b-4 p-5 ">
          <div className=" w-full">
            <h1 className="text-lg font-bold mb-2 ">{index + 1} - </h1>
            <h1 className="text-lg font-bold mb-2 ">
              Date Submitted - {conDate(applicant.submittedAt)}
            </h1>
          </div>
          <div className="flex-1 lg:flex lg:items-center lg:space-x-6">
            <div className="space-y-1 lg:space-y-0 lg:flex lg:items-center">
              <label className="inline lg:inline-block">First Name:</label>
              <span className="ml-3 p-2 lg:p-0 block lg:inline-block">
                {applicant.firstName}
              </span>
            </div>

            <div className="space-y-1 lg:space-y-0 lg:flex lg:items-center">
              <label className="block lg:inline-block">Last Name:</label>
              <span className="ml-3 p-2 lg:p-0 block lg:inline-block">
                {applicant.lastName}
              </span>
            </div>

            <div className="space-y-1 lg:space-y-0 lg:flex lg:items-center">
              <label className="block lg:inline-block">Email Address:</label>
              <span className="ml-3 p-2 lg:p-0 block lg:inline-block">
                {applicant.email}
              </span>
            </div>

            <div className="space-y-1 lg:space-y-0 lg:flex lg:items-center">
              <label className="block lg:inline-block">Phone Number:</label>
              <span className="ml-3 p-2 lg:p-0 block lg:inline-block">
                {applicant.phoneNumber}
              </span>
            </div>
          </div>
          <div className=" md:grid md:grid-cols-2">
            <div className="">
              <div className="m-3  ">
                {applicant.amcPart1Completed && (
                  <div className=" mt-3">
                    <label>AMC Part 1 Completed</label>
                    <input
                      className="w-5 h-5 ml-2"
                      type="checkbox"
                      checked={applicant.amcPart1Completed}
                      readOnly
                    />
                    {applicant.amcPart1Report && (
                      <div className="mt-2 ">
                        <a
                          href={applicant.amcPart1Report}
                          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 border border-blue-500 rounded shadow"
                        >
                          View AMC Part1 Report
                        </a>
                      </div>
                    )}
                  </div>
                )}

                {applicant.amcPart2Completed && (
                  <div className=" mt-3">
                    <label>AMC Part 2 Completed</label>
                    <input
                      className="w-5 h-5 ml-2"
                      type="checkbox"
                      checked={applicant.amcPart2Completed}
                      readOnly
                    />
                    {applicant.amcPart2Certificate && (
                      <div className="mt-2 lg:mt-0">
                        <a
                          href={applicant.amcPart2Certificate}
                          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 border border-blue-500 rounded shadow"
                        >
                          View AMC Certificate
                        </a>
                      </div>
                    )}
                  </div>
                )}

                {applicant.pesciCompleted && (
                  <div className=" mt-3">
                    <label>PESCI Completed</label>
                    <input
                      className="w-5 h-5 ml-2"
                      type="checkbox"
                      checked={applicant.pesciCompleted}
                      readOnly
                    />
                    {applicant.pesciDate && (
                      <div className="mt-2 lg:mt-0">
                        <label>Date Completed:</label>
                        <span className="ml-3">
                          {new Date(applicant.pesciDate).toLocaleDateString()}
                        </span>
                      </div>
                    )}
                    {applicant.pesciDocument && (
                      <div className="mt-2 lg:mt-0">
                        <a
                          href={applicant.pesciDocument}
                          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 border border-blue-500 rounded shadow"
                        >
                          View PESCI
                        </a>
                      </div>
                    )}
                  </div>
                )}

                {applicant.englishTestCompleted && (
                  <div className="mt-3">
                    <label>English Test Completed</label>
                    <input
                      className="w-5 h-5 ml-2"
                      type="checkbox"
                      checked={applicant.englishTestCompleted}
                      readOnly
                    />
                    {applicant.englishTestType && (
                      <div className="mt-2 lg:mt-0">
                        <label>Type of English Test:</label>
                        <span className="ml-3">
                          {applicant.englishTestType}
                        </span>
                      </div>
                    )}
                    {applicant.englishTestDate && (
                      <div className="mt-2 lg:mt-0">
                        <label>Date Completed:</label>
                        <span className="ml-3">
                          {new Date(
                            applicant.englishTestDate
                          ).toLocaleDateString()}
                        </span>
                      </div>
                    )}
                    {applicant.englishTestDocument && (
                      <div className="mt-5">
                        <a
                          href={applicant.englishTestDocument}
                          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 border border-blue-500 rounded shadow"
                        >
                          View English Test Document
                        </a>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {applicant.resume && (
                <div className="mt-5 p-2">
                  <a
                    href={applicant.resume}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 border border-blue-500 rounded shadow"
                  >
                    View Resume
                  </a>
                </div>
              )}
              <div>
                {applicant.interviewCompleted ? (
                  <div className="mt-5 text-2xl font-extrabold via-blue-900">
                    Interview Date:{" "}
                    {new Date(applicant.interviewDate).toLocaleDateString()}
                  </div>
                ) : (
                  <div className="mt-5">
                    <button
                      onClick={() => {
                        setOpen(true);
                        setTargetApplicant(applicant);
                      }}
                      className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 border border-blue-500 rounded shadow"
                    >
                      Update Interview
                    </button>
                  </div>
                )}
              </div>
            </div>
            {applicant.interviewCompleted && (
              <div className="  w-full sm:mt-5 ">
                {applicant.proceed === true ? (
                  <div className="w-full p-3  mx-auto flex justify-center items-center gap-2">
                    <HandThumbUpIcon className="h-[60px] w-[60px] text-green-700 " />
                    <h1>{applicant.interviewComment}</h1>
                  </div>
                ) : applicant.proceed === null ? (
                  <div className="w-full p-3  mx-auto">
                    <div className="relative w-full ">
                      <textarea
                        onChange={(e) => setInterviewComment(e.target.value)}
                        className="peer h-full min-h-[150px]  w-full max-w-[100%] resize-none rounded-[7px] border border-blue-gray-200 bg-transparent px-3 py-2.5 font-sans text-lg font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-gray-900   disabled:resize-none disabled:border-0 disabled:bg-blue-gray-50 "
                        placeholder="Interview comment"
                      ></textarea>
                      <div className=" flex justify-around">
                        {" "}
                        <HandThumbUpIcon
                          onClick={() =>
                            accepted(applicant._id, interviewComment)
                          }
                          class="h-[60px] w-[60px] text-green-700 cursor-pointer hover:text-green-900 hover:h-[65px] hover:w-[65px]"
                        />
                        <HandThumbDownIcon
                          onClick={() =>
                            rejected(applicant._id, interviewComment)
                          }
                          class="h-[60px] w-[60px] text-red-700 cursor-pointer hover:text-red-900 hover:h-[65px] hover:w-[65px]"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  applicant.proceed === false && (
                    <div className="w-full p-3  mx-auto flex justify-center items-center gap-2">
                      <HandThumbDownIcon className="h-[60px] w-[60px] text-red-700 " />
                      <h1>
                        {applicant.interviewComment &&
                          applicant.interviewComment}
                      </h1>
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}

export default ApplicantsTable;
