import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ApplicantsTable from "./ApplicantsTable ";
import { FadeLoader } from "react-spinners";

function AdminDashboard() {
  const [error, setError] = useState("");
  const [applicants, setApplicants] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    fetchApplicants(token);
  }, []);

  const fetchApplicants = async (token) => {
    setLoading(true);
    try {
      const applicantsResponse = await fetch("/api/admin/applicants", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
      });
      if (applicantsResponse.ok) {
        setLoading(false);
      } else {
        setLoading(false);
        if (applicantsResponse.statusText === "Unauthorized" && token) {
          handleLogout();
        }
        setError(applicantsResponse.statusText);
      }
      const applicantsData = await applicantsResponse.json();
      setApplicants(applicantsData);
    } catch (error) {
      setLoading(false);
      setError("An error occurred while fetching applicants.");
    }
  };

  const updateInterview = async (
    applicantId,
    interviewCompleted,
    interviewDate
  ) => {
    try {
      const response = await fetch(`/applicants/${applicantId}/interview`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          authorization: token,
        },
        body: JSON.stringify({
          interviewCompleted,
          interviewDate,
        }),
      });

      // Z
    } catch (error) {
      setError(error);
      console.error("Error:", error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/login");
  };
  if (loading) {
    return (
      <div>
        <FadeLoader
          color="#3f4540"
          cssOverride={{
            margin: "300px auto",
          }}
        />
      </div>
    );
  }
  return (
    <div className="form-container mt-[100px] flex items-center justify-center min-h-[600px]">
      {error ? (
        <p>{error}</p>
      ) : (
        <div>
          <div className=" flex justify-around">
            <h2 className=" text-3xl">Applicants</h2>
            <button
              onClick={handleLogout}
              className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
            >
              Logout
            </button>
          </div>

          <div className="mt-8 p-4">
            <h2 className="text-xl font-bold mb-4">Applicants List</h2>
            {applicants.length > 0 && (
              <ApplicantsTable
                applicants={applicants}
                updateInterview={updateInterview}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminDashboard;
