import { useEffect, useState } from "react";
import FormInput from "./FormInput";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import { FaEnvelopeOpenText } from "react-icons/fa";
import { FadeLoader } from "react-spinners";
import { motion } from "framer-motion";

const Form = () => {
  const [values, setValues] = useState({
    username: "",
    email: "",
    subject: "",
    message: "",
    resume: "",
    AMCpart1: "",
  });
  const [loading, setLoading] = useState(false);
  const [errorMSG, setErrorMSG] = useState("");

  const inputs = [
    {
      id: 1,
      name: "username",
      type: "text",
      placeholder: "Full Name",
      errorMessage:
        "Full Name should be 3-16 characters and shouldn't include any special character!",
      label: "Full Name",
      pattern: "^[A-Za-z ]{3,16}",
      required: true,
    },
    {
      id: 2,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      pattern: `^[a-z0-9._%+-]+@[a-z0-9.-]+[\.a-z]{2,4}$`,
      required: true,
    },
    {
      id: 3,
      name: "subject",
      type: "text",
      placeholder: "Subject",
      label: "subject",
      errorMessage: "Please tell us what this message about!",
      required: true,
    },
    {
      id: 4,
      name: "message",
      type: "textArea",
      placeholder: "message",
      errorMessage: "message should be 8-300 characters !",
      label: "message",
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,300}$`,
      required: true,
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        // process.env.REACT_APP_SERVICE_ID,
        "service_yrsw61d",
        "template_4yhb67s",
        values,
        // process.env.REACT_APP_KEY
        "bz-fun-EJgSe3f6Ob"
      )
      .then(
        (result) => {
          if (result.status === 200) {
            setLoading(false);

            toast.success(
              "Thank you for your message, we will respond as soon as possible",
              {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        },
        (error) => {
          setLoading(false);
          console.log(error.text);
        }
      );

    const reset = () => {
      setValues({
        username: "",
        email: "",
        subject: "",
        message: "",
      });
    };
    setTimeout(reset, 7000);
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  if (loading) {
    return (
      <div>
        <FadeLoader
          color="#3f4540"
          cssOverride={{
            margin: "300px auto",
          }}
        />
      </div>
    );
  }

  return (
    <div className="mt-[100px]" id="form-container">
      <form onSubmit={handleSubmit} id="form">
        <motion.div
          initial={{ opacity: 0.5, x: "-50%" }}
          transition={{ type: "spring", stiffness: 100, damping: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          style={{
            display: "flex",
            gap: "5px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FaEnvelopeOpenText style={{ fontSize: "30px" }} />{" "}
          <h2 className="text-4xl font-bold">Contact Us</h2>
        </motion.div>
        <span className="line"></span>

        <p
          style={{
            textAlign: "justify",
            marginBottom: "20px",
            marginTop: "50px",
            lineHeight: "30px",
          }}
        >
          Let <span style={{ fontFamily: "aboutFont" }}>SKT consulting</span> to
          help you making your journey smoth and well planned. Please send us an
          email on:
          <span
            style={{
              display: "flex",
              gap: "5px",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FaEnvelopeOpenText />
            <a href="mailto: info@skt-consulting.com">
              info@skt-consulting.com
            </a>
          </span>
          or, simply fill out the form below and we will contact you back as
          soon as possible
        </p>
        {errorMSG && (
          <div
            class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <strong class="font-bold">Error:</strong>
            <span class="block sm:inline">{errorMSG}</span>
            <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
              <svg
                class="fill-current h-6 w-6 text-red-500"
                role="button"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                onClick={() => {
                  setErrorMSG("");
                }}
              >
                <title>Close</title>
                <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
              </svg>
            </span>
          </div>
        )}
        {inputs.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={values[input.name]}
            onChange={onChange}
          />
        ))}

        <button className="button">Submit</button>
      </form>
    </div>
  );
};

export default Form;
