"use client";
import network from "../components/images/Network-New.jpg";
import plane from "../components/images/Plane.png";
import { useInView as inViewHook } from "react-intersection-observer";
import "./About-us.css";
import { motion, useInView } from "framer-motion";
import { Link } from "react-router-dom";
import { useRef } from "react";

const Connections = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });
  // const [myRef, inView, entry] = inViewHook();

  // console.log(inView);

  return (
    <div className="  about-us mt-[100px]" id="about-us">
      {/* <div ref={myRef} className="animation-container">
        {inView && (
          <motion.div
            className="moving-object"
            initial={{ x: 0, y: 0, scale: 0.25 }}
            animate={{ x: "100vw", y: "-100vh", scale: 1 }}
            transition={{ duration: 5, ease: "easeOut" }}
          >
            <img width="500px" src={plane} alt="network" loading="lazy" />
          </motion.div>
        )}
      </div> */}
      <motion.div
        initial={{ opacity: 0.5, scale: 0.5 }}
        transition={{ duration: 1 }}
        whileInView={{ opacity: 1, scale: 1 }}
        className="container"
      >
        <div id="network">
          <img wi src={network} alt="network" loading="lazy" />
        </div>

        <div className="col-2">
          <h2>Our Network</h2>
          <span className="line"></span>
        </div>
      </motion.div>
      <div
        id="content"
        className=" mt-10 w-[70vw] justify-center flex items-center"
      >
        <div className="w-full justify-center">
          <motion.div
            ref={ref}
            initial={{ opacity: 0.1 }}
            animate={{ opacity: isInView ? 1 : 0.5 }}
            transition={{ duration: 0.5 }}
          >
            <p className=" text-justify text-[1.2rem] md:text-[2rem] mb-5 mt-5">
              <span style={{ fontFamily: "aboutFont" }}>
                St. KittsTrinity Consulting AG{" "}
              </span>
              is proud to collaborate with a diverse and extensive network of
              partners and affiliates across the healthcare and migration
              sectors. This network enables us to provide unparalleled support
              and resources to overseas GPs seeking to migrate to Australia.
            </p>
          </motion.div>
          <br />
          <br />
          <motion.div
            style={{ margin: "0 auto" }}
            ref={ref}
            initial={{ opacity: 0 }}
            animate={{ opacity: isInView ? 1 : 0 }}
            transition={{ duration: 0.5 }}
          >
            <p className=" lg:p-10 text-justify text-[1.2rem] md:text-[2rem] mb-5">
              <br /> At{" "}
              <span style={{ fontFamily: "aboutFont" }}>
                St. KittsTrinity Consulting AG,{" "}
              </span>
              We have established strong relationships with leading medical
              practices across Australia, ensuring our clients have access to
              the best job opportunities and professional environments. <br />
              <br />
              We work closely with certified migration agents and legal experts
              specializing in medical migration to Australia. Their expertise
              ensures a smooth and efficient migration process for our clients.{" "}
              <br />
              <br />
              Our partnerships with leading healthcare services enable us to
              match overseas GPs with the best available positions, ensuring
              both professional satisfaction and career growth.
              <br />
              <br />
              Through our network, we have successfully assisted numerous GPs in
              their journey to practice medicine in Australia.
              <br />
              <br />
              Our network isn’t limited to Australia. We have connections with
              medical professionals and organizations worldwide, ensuring we can
              support GPs no matter where they are coming from.
              <br />
              <br />
            </p>
          </motion.div>
          <motion.div
            whileHover={{
              boxShadow: " rgba(250, 250, 250, 0.19) 0px 0px 10px 10px",
              scale: 1.1,
            }}
            smooth={true}
            duration={700}
            offset={-100}
            className="w-full flex justify-center"
          >
            <Link
              smooth={true}
              offset={-100}
              duration={700}
              to="/contact-us"
              onClick={() => window.scrollTo(0, 0)}
              className="button lg:w-1/4 md:w-1/2 w-full self-center mb-5 justify-center items-center flex"
            >
              <div smooth={true} duration={700} offset={-100}>
                Contact us
              </div>
            </Link>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Connections;
