"use client";
import React from "react";
import "./Hero.css";
import { motion } from "framer-motion";
import hero from "./images/hero.png";
import heroM from "./images/hero-m.png";
import logo from "./images/world.png";
import { Link as ScrollLink } from "react-scroll";
import { Link, Link as RouterLink } from "react-router-dom";

const Hero = () => {
  const width = window.screen.width;
  return (
    <div className="hero" id="home">
      <img
        loading="lazy"
        src={width > 600 ? hero : heroM}
        className="w-full h-full object-cover
         absolute  "
        alt="flag"
      />

      <motion.div
        initial={{ opacity: 0.5, scale: 0.5 }}
        transition={{ duration: 2 }}
        whileInView={{ opacity: 1, scale: 1 }}
        className="content"
      >
        <div id="hero-img" className="flex w-full">
          {" "}
          <img src={logo} width={300} height={300} alt="logo" />
          <div className="innerContent">
            <h1 className=" text-center">
              Navigating Your Path to New Horizons
            </h1>
            <p className=" text-justify">
              <span>St. KittsTrinity Consulting</span> provides experienced
              consultation services to overseas GPs to make their dream journey
              to Australia as smooth as possible
            </p>
          </div>
        </div>

        <div className="flex button-group justify-around ">
          <ScrollLink smooth={true} offset={-100} duration={700} to="contact">
            <motion.button
              whileHover={{
                boxShadow: " rgba(250, 250, 250, 0.19) 0px 0px 10px 10px",
                scale: 1.1,
              }}
              className="bg-slate-950 hover:bg-slate-700 text-white font-bold  p-5 rounded m-3"
              to="contact"
              smooth={true}
              duration={700}
              offset={-100}
            >
              Contact us
            </motion.button>
          </ScrollLink>

          <motion.button
            whileHover={{
              boxShadow: " rgba(250, 250, 250, 0.19) 0px 0px 10px 10px",
              scale: 1.1,
            }}
            className="bg-slate-950 hover:bg-slate-700 text-white font-bold  p-5 rounded m-3"
          >
            <Link to="/apply">New Application</Link>
          </motion.button>
        </div>
      </motion.div>
    </div>
  );
};

export default Hero;
