"use client";
import React, { useState } from "react";
import "./Vision.css";
import { GiClick } from "react-icons/gi";
import Modal from "./Modal";
import { motion } from "framer-motion";

const Vision = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState(null);
  const data = {
    vision: {
      title: "Our Vision",
      text: "To be the leading provider of expert guidance and support for General Practitioners worldwide, ensuring a seamless and successful transition to practice in Australia, fostering professional growth, and enhancing the healthcare community.",
    },
    mission: {
      title: "Our Mission",
      text: "Our mission is to empower General Practitioners with comprehensive, personalized advice and resources for migrating to Australia. We strive to simplify the migration process, from visa applications to professional registration and employment opportunities, while offering unwavering support in navigating cultural and practical challenges. Our commitment is to excellence, integrity, and the well-being of our clients, helping them to achieve their professional aspirations and contribute to the Australian healthcare system.",
    },
  };

  const toggleOpen = (content) => {
    setIsOpen((prev) => !prev);
    setContent(content);
  };
  return (
    <div className="vision" id="vision">
      <motion.div
        initial={{ opacity: 0.5, scale: 0.5 }}
        transition={{ duration: 2 }}
        whileInView={{ opacity: 1, scale: 1 }}
        className="container"
      >
        <div className="col-1" onClick={() => toggleOpen(data.vision)}>
          <img
            style={{ objectFit: "contain" }}
            src={require("./images/Vision.jpg")}
            alt="vision"
          />
        </div>
        <div className="col-2" onClick={() => toggleOpen(data.mission)}>
          <img
            style={{ objectFit: "contain" }}
            src={require("./images/Mission.jpg")}
            alt="mission"
          />
        </div>
      </motion.div>
      <Modal isOpen={isOpen} setIsOpen={setIsOpen} content={content} />
    </div>
  );
};

export default Vision;
