import React from "react";
import "./legal.css";
import { Link } from "react-router-dom";
import MyComponent from "../../components/Google";

const Legal = () => {
  return (
    <div
      className="legal mt-[100px]"
      style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}
    >
      <h1 className=" text-center m-5">Imprint</h1>
      <section>
        <h2>Business Information</h2>
        <div className="p-10 flex flex-col gap-2">
          <p>
            <strong>Company Name:</strong> St. KittsTrinity Consulting AG
          </p>
          <p>
            <strong>Address:</strong> 26 Anthipolochagou Georgiou M. Savva Shop
            1-2 8201 Paphos Cyprus
          </p>
          <p>
            <strong>Phone:</strong>{" "}
            <a href="tel:+357 80080585">+357 80080585</a>
          </p>
          <p>
            <strong>Email:</strong>{" "}
            <a href="mailto:info@skt-consulting.com">info@skt-consulting.com</a>
          </p>
          <p>
            <strong>Website:</strong> www.skt-consulting.com
          </p>
        </div>
      </section>
      <section>
        <h2>Location of operating adress</h2>
        <div className="p-10 flex flex-col gap-2">
          <p>
            26 Anthipolochagou Georgiou M. Savva Shop 1-2 8201 Paphos Cyprus
          </p>
        </div>
      </section>
      {/* <section>
        <h2>Regulatory Authority</h2>
        <div className="p-10 flex flex-col gap-2">
          <p>
            Registered with the Australian Securities & Investments Commission
            (ASIC)
          </p>
        </div>
      </section> */}
      <section>
        <h2>Contact</h2>
        <div className="p-10 flex flex-col gap-2">
          <p>
            If you have any questions about this Imprint, please contact us at:
          </p>
          <p>
            <strong>Email:</strong>{" "}
            <a href="mailto:info@skt-consulting.com">info@skt-consulting.com</a>
          </p>
          <p>
            <strong>Phone:</strong>{" "}
            <a href="tel:+357 80080585">+357 80080585</a>{" "}
          </p>
        </div>
      </section>
      <section>
        <h2>Disclaimer</h2>
        <div className="p-10 flex flex-col gap-2 ">
          <p className=" text-justify">
            All information provided on this website is for general
            informational purposes only and is not intended to constitute
            professional advice. While we strive to keep the information up to
            date and correct, we make no representations or warranties of any
            kind, express or implied, about the completeness, accuracy,
            reliability, suitability, or availability with respect to the
            website or the information, products, services, or related graphics
            contained on the website for any purpose. Any reliance you place on
            such information is therefore strictly at your own risk.
            <br />
            <br /> In no event will we be liable for any loss or damage
            including without limitation, indirect or consequential loss or
            damage, or any loss or damage whatsoever arising from loss of data
            or profits arising out of, or in connection with, the use of this
            website.
          </p>
        </div>
      </section>
      <section>
        <h2>Copyright</h2>
        <div className="p-10 flex flex-col gap-2 ">
          <p className=" text-justify mb-2">
            © 2024 St. KittsTrinity Consulting AG. All rights reserved.
          </p>
          <p className="text-justify">
            Unauthorized use and/or duplication of this material without express
            and written permission from this site’s author and/or owner is
            strictly prohibited. Excerpts and links may be used, provided that
            full and clear credit is given to Global GP Consultancy Pty Ltd with
            appropriate and specific direction to the original content.
          </p>
        </div>
      </section>
      <section>
        <h2> Privacy Policy</h2>
        <div className="p-10 flex flex-col gap-2 ">
          <p className=" text-justify">
            For information on how we collect and use personal data, please
            refer to our{" "}
            {
              <Link className=" underline text-blue-400" to="/privacy">
                Privacy Policy
              </Link>
            }
            .
          </p>
        </div>
      </section>
      <MyComponent id="google" />
    </div>
  );
};

export default Legal;
