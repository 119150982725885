import React, { useState } from "react";
import ApplicationForm from "./ApplicationForm";
import { FaWpforms, FaClinicMedical, FaFileContract } from "react-icons/fa";

const Apply = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div
      id="app-container"
      className="flex items-center justify-center min-h-screen bg-gray-100 h-full "
    >
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
        <div className="bg-white rounded-lg shadow-lg p-8 max-w-3xl w-full">
          <h1 className="text-3xl font-bold text-center text-blue-600 mb-8">
            Application Process
          </h1>
          <div className="space-y-8">
            {/* Step 1 */}
            <div className="flex items-start">
              <div className="text-blue-500">
                <FaWpforms size={40} />
              </div>
              <div className="ml-4">
                <h2 className="text-2xl font-semibold">
                  1. Submit Your Application
                </h2>
                <p className="text-gray-600 mt-2">
                  Fill in and submit the application form with all the required
                  information.
                </p>
              </div>
            </div>

            {/* Step 2 */}
            <div className="flex items-start">
              <div className="text-green-500">
                <FaClinicMedical size={40} />
              </div>
              <div className="ml-4">
                <h2 className="text-2xl font-semibold">2. Interview</h2>
                <p className="text-gray-600 mt-2">
                  You will be contacted to have an interview with one of our
                  partner clinics in Victoria.
                </p>
              </div>
            </div>

            {/* Step 3 */}
            <div className="flex items-start">
              <div className="text-purple-500">
                <FaFileContract size={40} />
              </div>
              <div className="ml-4">
                <h2 className="text-2xl font-semibold">
                  3. Post-Interview Process
                </h2>
                <p className="text-gray-600 mt-2">
                  PESCI, agreement, and other application fees will be discussed
                  after passing the interview.
                </p>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={toggleModal}
          className="px-4 py-2 font-semibold text-white bg-blue-500 rounded hover:bg-blue-700 mt-10"
        >
          Start Application
        </button>
      </div>

      {isOpen && (
        <div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 mt-10 "
          onClick={() => setIsOpen(false)}
        >
          <div
            className="bg-white rounded-lg shadow-lg w-[90%] max-w-[800px] h-full overflow-auto "
            onClick={(e) => e.stopPropagation()}
          >
            <div className="">
              {/* <h2 className="text-3xl font-semibold">New Application</h2> */}
              <ApplicationForm />
            </div>
            <div className="flex justify-end px-6 py-4">
              <button
                onClick={toggleModal}
                className="px-4 py-2 font-semibold text-gray-700 bg-gray-200 rounded hover:bg-gray-300"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Apply;
