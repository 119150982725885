"use client";
import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import "./Services.css";
import { GiClick } from "react-icons/gi";
import { motion } from "framer-motion";

const Services = () => {
  const [isFlipped, setIsFlipped] = useState([false, false, false, false]);
  const flipCard = (i) => {
    setIsFlipped(
      isFlipped.map((item, index) => {
        if (index === i) {
          return !item;
        } else {
          return item;
        }
      })
    );
  };

  const services = [
    {
      id: 1,
      title: "Visa Consultation",
      text: "Detailed and professional advice on the types of visas available for medical professionals.",
    },
    {
      id: 2,
      title: "Application Processing",
      text: "Assistance with the preparation and submission of visa applications.",
    },
    {
      id: 3,
      title: "Documentation Support",
      text: "Help with gathering and preparing required documentation.",
    },
    {
      id: 4,
      title: "Guidance on Medical Registration",
      text: "Information on the process of getting registered with the Medical Board of Australia.",
    },
  ];
  return (
    <div className="services" id="services">
      <motion.div
        initial={{ opacity: 0.5, scale: 0.5 }}
        transition={{ duration: 2 }}
        whileInView={{ opacity: 1, scale: 1 }}
        className="container"
      >
        <h2>How Can We Help You</h2>
        <span className="line"></span>
        <div className="content">
          {services.map((service) => (
            <ReactCardFlip
              key={service.id}
              flipDirection="horizontal"
              isFlipped={isFlipped[service.id - 1]}
            >
              <div
                className="card-front"
                onClick={() => flipCard(service.id - 1)}
              >
                <h3>{service.title}</h3>
                <GiClick size={75} />
                {/* <button className="button">Explore</button> */}
              </div>
              <div
                className="card flex items-center"
                onClick={() => flipCard(service.id - 1)}
              >
                <p className=" text-justify">{service.text}</p>
              </div>
            </ReactCardFlip>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default Services;
