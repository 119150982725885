import React from "react";
import Hero from "../components/Hero";
import About from "../components/About";
import Vision from "../components/Vision";
import Services from "../components/Services";
import Demo from "../components/Demo";
import MyComponent from "../components/Google";

const Home = () => {
  return (
    <div>
      <Hero />
      <About />
      <Vision />
      <Services />
      <Demo />
      <MyComponent />
    </div>
  );
};

export default Home;
