import emailjs from "@emailjs/browser";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FadeLoader } from "react-spinners";
import { toast } from "react-toastify";

function ApplicationForm() {
  const [amcPart1, setAmcPart1] = useState(false);
  const [amcPart2, setAmcPart2] = useState(false);
  const [pesci, setPesci] = useState(false);
  const [englishTest, setEnglishTest] = useState(false);
  const [errors, setErrors] = useState({});
  const [submitError, setSubmitError] = useState("");
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [values, setValues] = useState({
    username: "",
    email: "",
    message: "New Application",
  });

  const navigate = useNavigate();

  const validateFileType = (file) => {
    const allowedTypes = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ];
    return file && allowedTypes.includes(file.type);
  };

  const validateForm = (formData) => {
    const newErrors = {};

    if (!formData.get("firstName"))
      newErrors.firstName = "First name is required.";
    if (!formData.get("lastName"))
      newErrors.lastName = "Last name is required.";
    if (!formData.get("email")) newErrors.email = "Email is required.";
    if (!formData.get("phoneNumber"))
      newErrors.phoneNumber = "Phone number is required.";

    if (amcPart1) {
      const amcPart1Report = formData.get("amcPart1Report");

      if (
        !validateFileType(amcPart1Report) &&
        typeof amcPart1Report === "object"
      ) {
        newErrors.amcPart1Report =
          "AMC Part 1 Report must be a .pdf, .doc, or .docx file.";
      }
    }

    if (amcPart2) {
      const amcPart2Certificate = formData.get("amcPart2Certificate");
      if (
        !validateFileType(amcPart2Certificate) &&
        typeof amcPart2Certificate === "object"
      ) {
        newErrors.amcPart2Certificate =
          "AMC Part 2 Certificate must be a .pdf, .doc, or .docx file.";
      }
    }

    if (pesci) {
      const pesciDocument = formData.get("pesciDocument");
      if (!formData.get("pesciDate") && typeof pesciDate === "object")
        newErrors.pesciDate = "PESCI Date is required.";
      if (
        !validateFileType(pesciDocument) &&
        typeof pesciDocument === "object"
      ) {
        newErrors.pesciDocument =
          "PESCI Supporting Document must be a .pdf, .doc, or .docx file.";
      }
    }

    if (englishTest) {
      const englishTestDocument = formData.get("englishTestDocument");

      if (
        !validateFileType(englishTestDocument) &&
        typeof englishTestDocument === "object"
      ) {
        newErrors.englishTestDocument =
          "English Test Supporting Document must be a .pdf, .doc, or .docx file.";
      }
    }

    const resume = formData.get("resume");
    if (!validateFileType(resume) && typeof resume === "object") {
      newErrors.resume = "Resume must be a .pdf, .doc, or .docx file.";
    }

    return newErrors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setValues({ ...values, username: firstName + " " + lastName });
    setLoading(true);

    const formData = new FormData();

    formData.append("firstName", event.target.firstName.value);
    formData.append("lastName", event.target.lastName.value);
    formData.append("email", event.target.email.value);
    formData.append("phoneNumber", event.target.phoneNumber.value);

    formData.append("amcPart1Completed", amcPart1 ? 1 : 0);
    formData.append("amcPart2Completed", amcPart2 ? 1 : 0);
    formData.append("pesciCompleted", pesci ? 1 : 0);
    formData.append("englishTestCompleted", englishTest ? 1 : 0);

    if (amcPart1) {
      formData.append("amcPart1Report", event.target.amcPart1Report.files[0]);
    }

    if (amcPart2) {
      formData.append(
        "amcPart2Certificate",
        event.target.amcPart2Certificate.files[0]
      );
    }

    if (pesci) {
      formData.append("pesciDate", event.target.pesciDate.value);
      formData.append("pesciDocument", event.target.pesciDocument.files[0]);
    }

    if (englishTest) {
      formData.append("englishTestType", event.target.englishTestType.value);
      formData.append("englishTestDate", event.target.englishTestDate.value);
      formData.append(
        "englishTestDocument",
        event.target.englishTestDocument.files[0]
      );
    }

    formData.append("resume", event.target.resume.files[0]);

    // Validate the form data before submission
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setLoading(false);
      return;
    }

    setErrors({});
    setSubmitError("");

    try {
      // Validate the email first
      const validateEmailResponse = await fetch("/api/email/validate-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email: formData.get("email") }),
      });

      if (!validateEmailResponse.ok) {
        setLoading(false);
        const errorData = await validateEmailResponse.json();
        setSubmitError(errorData.error || "Failed to validate email.");
        return;
      }

      // If email validation passes, submit the application
      const response = await fetch("/api/submit-application", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        setLoading(false);
        emailjs
          .send(
            "service_yrsw61d",
            "template_4yhb67s",
            values,
            "bz-fun-EJgSe3f6Ob"
          )
          .then(
            (result) => {
              if (result.status === 200) {
                toast.success(
                  "Application was submitted successfully, we will respond as soon as possible",
                  {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  }
                );
                setLoading(false);
                navigate("/");
              }
            },
            (error) => {
              setLoading(false);
              console.log(error.text);
            }
          );
      } else {
        setLoading(false);
        const errorData = await response.json();
        setSubmitError(errorData.error || "Failed to submit the form.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error submitting the form:", error);
      setSubmitError("Error submitting the form. Please try again later.");
    }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const logDate = (e) => {
    console.log(e.target.value);
  };

  if (loading) {
    return (
      <div>
        <FadeLoader
          color="#3f4540"
          cssOverride={{
            margin: "300px auto",
          }}
        />
      </div>
    );
  }

  return (
    <div className=" p-[20px] flex flex-col justify-center items-center">
      <div className="flex justify-center flex-col items-center">
        <h1 className="text-3xl font-serif">New application form</h1>
        <p className="p-5 max-w-[600px] text-justify">
          Please start your new application and provide as much information as
          possible. That will help us assess your application more effectively.
          After we have completed the assessment, we will email you regarding
          the next steps.
        </p>
      </div>
      <form onSubmit={handleSubmit} className="px-5">
        <div className=" w-full md:w-3/7">
          <div>
            <label>First Name:</label>
            <input
              type="text"
              name="firstName"
              required
              onChange={(e) => setFirstName(e.target.value)}
            />
            {errors.firstName && (
              <p className="text-red-500">{errors.firstName}</p>
            )}
          </div>

          <div>
            <label>Last Name:</label>
            <input
              type="text"
              name="lastName"
              required
              onChange={(e) => setLastName(e.target.value)}
            />
            {errors.lastName && (
              <p className="text-red-500">{errors.lastName}</p>
            )}
          </div>

          <div>
            <label>Email Address:</label>
            <input type="email" name="email" required onChange={onChange} />
            {errors.email && <p className="text-red-500">{errors.email}</p>}
          </div>

          <div>
            <label>Phone Number (Including Country Code):</label>
            <input type="tel" name="phoneNumber" required onChange={onChange} />
            {errors.phoneNumber && (
              <p className="text-red-500">{errors.phoneNumber}</p>
            )}
          </div>
          <div>
            <div className="mb-5">
              <label>English Test Completed</label>
              <input
                className="w-20"
                type="checkbox"
                checked={englishTest}
                onChange={(e) => setEnglishTest(e.target.checked)}
              />
              {englishTest && (
                <div>
                  <label>Type of English Test:</label>
                  <input type="text" name="englishTestType" />
                  {errors.englishTestType && (
                    <p className="text-red-500">{errors.englishTestType}</p>
                  )}

                  <label>Date Completed:</label>
                  <input
                    type="date"
                    name="englishTestDate"
                    className="max-w-[550px] block"
                  />
                  {errors.englishTestDate && (
                    <p className="text-red-500">{errors.englishTestDate}</p>
                  )}

                  <label>Upload Supporting Document:</label>
                  <input type="file" name="englishTestDocument" />
                  {errors.englishTestDocument && (
                    <p className="text-red-500">{errors.englishTestDocument}</p>
                  )}
                </div>
              )}
            </div>
            <div className="mb-5">
              <label>AMC Part 1 Completed</label>
              <input
                className="w-20"
                type="checkbox"
                checked={amcPart1}
                onChange={(e) => setAmcPart1(e.target.checked)}
              />
              {amcPart1 && (
                <div>
                  <label>Upload AMC Part 1 Report:</label>
                  <input type="file" name="amcPart1Report" />
                  {errors.amcPart1Report && (
                    <p className="text-red-500">{errors.amcPart1Report}</p>
                  )}
                </div>
              )}
            </div>

            <div className="mb-5">
              <label>AMC Part 2 Completed</label>
              <input
                className="w-20"
                type="checkbox"
                checked={amcPart2}
                onChange={(e) => setAmcPart2(e.target.checked)}
              />
              {amcPart2 && (
                <div>
                  <label>Upload AMC Certificate:</label>
                  <input type="file" name="amcPart2Certificate" />
                  {errors.amcPart2Certificate && (
                    <p className="text-red-500">{errors.amcPart2Certificate}</p>
                  )}
                </div>
              )}
            </div>

            <div className="mb-5">
              <label>PESCI Completed</label>
              <input
                className="w-20"
                type="checkbox"
                checked={pesci}
                onChange={(e) => setPesci(e.target.checked)}
              />
              {pesci && (
                <div>
                  <label>Date Completed:</label>
                  <input
                    type="date"
                    name="pesciDate"
                    className="max-w-[550px] block"
                    onChange={logDate}
                  />
                  {errors.pesciDate && (
                    <p className="text-red-500">{errors.pesciDate}</p>
                  )}

                  <label>Upload Supporting Document:</label>
                  <input type="file" name="pesciDocument" />
                  {errors.pesciDocument && (
                    <p className="text-red-500">{errors.pesciDocument}</p>
                  )}
                </div>
              )}
            </div>
          </div>
          <div>
            <label>Upload Resume:</label>
            <input type="file" name="resume" required />
            {errors.resume && <p className="text-red-500">{errors.resume}</p>}
          </div>

          {submitError && <p className="text-red-500">{submitError}</p>}

          <button
            className="m-5 shadow bg-blue-500 hover:bg-blue-400 focus:shadow-outline focus:outline-none text-white font-bold py-2 px-4 rounded"
            type="submit"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default ApplicationForm;
