import React from "react";
import "./404.css";
import { FaEnvelopeOpenText } from "react-icons/fa";

const NotFound = () => {
  return (
    <div className="container text-center justify-center h-[100vh] " id="error">
      <svg height="100" width="100">
        <polygon
          points="50,25 17,80 82,80"
          stroke-linejoin="round"
          style={{ fill: "none", stroke: "#ff8a00", strokeWidth: 8 }}
        />
        <text
          x="42"
          y="74"
          fill="#ff8a00"
          fontFamily="sans-serif"
          fontWeight="900"
          fontSize="42px"
        >
          !
        </text>
      </svg>
      <div class="row">
        <div class="col-md-12">
          <div class="main-icon text-warning">
            <span class="uxicon uxicon-alert"></span>
          </div>
          <h1>File not found (404 error)</h1>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-md-push-3">
          <p class="lead">
            If you think what you're looking for should be here, please contact
            the site owner on{" "}
            <span
              style={{
                display: "flex",
                gap: "5px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaEnvelopeOpenText />
              <a href="mailto: info@skt-consulting.com">
                info@skt-consulting.com
              </a>
            </span>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
