"use client";
import React, { useState } from "react";
import about from "./images/about.jpg";
import "./About.css";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const About = () => {
  return (
    <div className="about" id="about">
      <motion.div
        initial={{ opacity: 0.5, scale: 0.5 }}
        transition={{ duration: 2 }}
        whileInView={{ opacity: 1, scale: 1 }}
        className="container"
      >
        <img src={about} alt="john" />
        <div className="col-2">
          <h2>About</h2>
          <span className="line"></span>
          <p style={{ textAlign: "justify", fontSize: "1.5rem" }}>
            <span style={{ fontFamily: "aboutFont" }}>SKT consulting</span> is
            your gateway to Australia.
          </p>
          <p style={{ textAlign: "justify", fontSize: "1.5rem" }}>
            {" "}
            At <span style={{ fontFamily: "aboutFont" }}>SKT consulting</span> ,
            we are dedicated to facilitating the process of moving to Australia
            by providing the most experienced and accurate information
          </p>

          <button className="button" onClick={() => window.scrollTo(0, 0)}>
            <Link to="/about-us">Find out more</Link>
          </button>
        </div>
      </motion.div>
    </div>
  );
};

export default About;
