import React, { useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

export default function TextEditor(props) {
  const { initialValue, setDesc } = props;
  const editorRef = useRef(null);
  const exportText = () => {
    if (editorRef.current) {
      setDesc(editorRef.current.getContent());
    }
  };
  return (
    <>
      <Editor
        apiKey="rxkbb79wv99j5xtska7n086uwdbfc4neky0oeshiikbum364"
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={initialValue}
        init={{
          height: 500,
          width: "80%",

          menubar: true,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",

            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic forecolor backcolor | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | table| searchreplace | image " +
            "removeformat | help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
        onChange={() => {
          if (editorRef.current) {
            setDesc(editorRef.current.getContent());
          }
        }}
      />
    </>
  );
}
