import React, { useState } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import { FaLocationDot } from "react-icons/fa6";
import { FaHeadset } from "react-icons/fa";
import "../index.css";
const containerStyle = {
  width: "100%",
  height: "600px",
};

const center = {
  lat: 34.77410045594056,
  lng: 32.42504213864194,
};

function MyComponent() {
  const [selected, setSelected] = useState(true);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAYelH_QTQy2jjf7eLwm-CIcI3yLkHR2Zo",
  });

  const [map, setMap] = useState(null);

  const geocodeAddress = async () => {
    const apiKey = "AIzaSyDxmd37Tf3a8nDJ0RaHaCDPRE3JGyvmFIU";
    const response = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json`,
      {
        params: {
          address:
            "26 Anthipolochagou Georgiou M. Savva Shop 1-2 8201 Paphos Cyprus",
          key: apiKey,
        },
      }
    );

    if (response.data.status === "OK") {
      const location = response.data.results[0].geometry.location;
      console.log({ lat: location.lat, lng: location.lng });
    } else {
      console.error("Error fetching geocode:", response.data.status);
    }
  };

  return isLoaded ? (
    <GoogleMap
      className="info-window"
      mapContainerStyle={containerStyle}
      center={center}
      zoom={18}
    >
      <Marker
        locationName="SKT Consulting"
        position={center}
        options={{
          icon: "https://i.imgur.com/9G5JOp8.png",
        }}
        onClick={() => setSelected(!selected)}
        label={{
          text: "SKT Consulting",
          color: "black",
          fontSize: "20px",
          fontWeight: "700",
          className: "mb-[-500px]  p-5 ",
        }}
      />
      {/* <div style={{ position: "relative" }}>
        <div
          style={{
            width: "90%",
            maxWidth: "300px",
            position: "absolute",
            top: "50px",
            left: "50px",
            backgroundColor: "white",
            margin: "10px",
            padding: "5px",
            borderRadius: "15px",
            zIndex: 5,
            boxShadow:
              "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
          }}
        >
          <h1 style={{ color: "red", fontSize: "20px" }}>
            St. KittsTrinity Consulting
          </h1>
          <div className="flex items-center gap-2 w-full  text-1xl mb-3">
            <FaLocationDot />
            <p>
              26 Anthipolochagou Georgiou M. Savva Shop 1-2 8201 Paphos Cyprus
            </p>
          </div>
          <div className="flex text-black">
            <FaHeadset />
            <p className="font-bold ">
              <a
                href="tel:+35780080585"
                aria-label="Call us at  +61 (3) 5670-8900"
                className=" ml-2"
              >
                +357 80080585
              </a>
            </p>
          </div>
        </div>
      </div> */}

      <div className="info-window">
        {selected && (
          <>
            <div style={{ position: "relative" }}>
              <div
                style={{
                  width: "90%",
                  maxWidth: "300px",
                  position: "absolute",
                  top: "50px",
                  left: "50px",
                  backgroundColor: "white",
                  margin: "10px",
                  padding: "5px",
                  borderRadius: "15px",
                  boxShadow:
                    "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                }}
              >
                <h1 style={{ color: "red", fontSize: "20px" }}>
                  St. KittsTrinity Consulting
                </h1>
                <div className="flex items-center gap-2 w-full  text-1xl mb-3">
                  <FaLocationDot />
                  <p>
                    26 Anthipolochagou Georgiou M. Savva Shop 1-2 8201 Paphos
                    Cyprus
                  </p>
                </div>
                <div className="flex text-black">
                  <FaHeadset />
                  <p className="font-bold ">
                    <a
                      href="tel:+35780080585"
                      aria-label="Call us at  +61 (3) 5670-8900"
                      className=" ml-2"
                    >
                      +357 80080585
                    </a>
                  </p>
                </div>
              </div>
            </div>
            {/* <InfoWindow
              position={center}
              onCloseClick={() => setSelected(false)}
              zIndex={100}
            >
              <div style={{ width: "250px", height: "100px" }}>
                <h1 style={{ color: "red", fontSize: "20px" }}>
                  St. KittsTrinity Consulting
                </h1>
                <div className="flex items-center gap-2 w-full  text-1xl mb-3">
                  <FaLocationDot />
                  <p>
                    26 Anthipolochagou Georgiou M. Savva Shop 1-2 8201 Paphos
                    Cyprus
                  </p>
                </div>
                <div className="flex text-black">
                  <FaHeadset />
                  <p className="font-bold ">
                    <a
                      href="tel:+35780080585"
                      aria-label="Call us at  +61 (3) 5670-8900"
                      className=" ml-2"
                    >
                      +357 80080585
                    </a>
                  </p>
                </div>
              </div>
            </InfoWindow> */}
          </>
        )}
      </div>
    </GoogleMap>
  ) : (
    <></>
  );
}

export default MyComponent;
