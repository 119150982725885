"use client";
import React, { useState } from "react";
import { FaBars, FaHeadset, FaTimes } from "react-icons/fa";
import logo from "./images/world2.png";

import "./Navbar.css";
import { Link } from "react-router-dom";
// import { Link } from "react-scroll";

const Navbar = () => {
  const [click, setClick] = useState(false);
  const [headerColor, setHeaderColor] = useState(false);

  const headerClass = () => {
    if (window.scrollY > 90) {
      setHeaderColor(true);
    } else {
      setHeaderColor(false);
    }
  };

  window.addEventListener("scroll", headerClass);

  const handleClick = () => setClick(!click);
  const closeMenu = () => setClick(false);

  return (
    <div className={headerColor ? "header nav-bg" : "header"}>
      <nav className="navbar">
        <div className="hamburger" onClick={handleClick}>
          {click ? (
            <FaTimes size={30} style={{ color: "#ffffff" }} />
          ) : (
            <FaBars size={30} style={{ color: "#ffffff" }} />
          )}
        </div>
        <div className=" flex-col phones">
          <div className="flex text-white">
            <FaHeadset />
            <p className="font-bold ">
              <a
                href="tel:+35780080585"
                aria-label="Call us at  +61 (3) 5670-8900"
                className=" ml-2"
              >
                CY +357 80080585
              </a>
            </p>
          </div>
          <div className="flex text-white">
            <FaHeadset />
            <p className="font-bold text-inherit">
              <a
                href="tel:+611800930985"
                aria-label="Call us at  +61 (3) 5670-8900"
                className=" ml-2"
              >
                AU +61 1800930985
              </a>
            </p>
          </div>
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link
              to="/"
              onClick={() => {
                closeMenu();
                window.scrollTo(0, 0);
              }}
              smooth={true}
              offset={0}
              duration={700}
            >
              Home
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/about-us"
              onClick={() => {
                closeMenu();
                window.scrollTo(0, 0);
              }}
              smooth={true}
              offset={10}
              duration={700}
            >
              About
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/contact-us"
              onClick={() => {
                closeMenu();
                window.scrollTo(0, 0);
              }}
              smooth={true}
              offset={-100}
              duration={700}
            >
              Contact
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/our-network"
              onClick={() => {
                closeMenu();
                window.scrollTo(0, 0);
              }}
              smooth={true}
              offset={-100}
              duration={700}
            >
              Our Network
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/apply"
              onClick={() => {
                closeMenu();
                window.scrollTo(0, 0);
              }}
              smooth={true}
              offset={-100}
              duration={700}
            >
              New Application
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link
              to="contact"
              onClick={closeMenu}
              smooth={true}
              offset={-100}
              duration={700}
            >
              Contact Us
            </Link>
          </li> */}
          <li className="nav-item text-white items-center flex justify-center md:hidden lg:hidden">
            <FaHeadset />
            <p className="font-bold text-inherit">
              <a
                href="tel:+35780080585"
                aria-label="Call us at  +61 (3) 5670-8900"
                className=" ml-2"
              >
                CY +357 80080585
              </a>
            </p>
          </li>
          <li className="nav-item text-white items-center flex justify-center md:hidden  lg:hidden">
            <FaHeadset />
            <p className="font-bold text-inherit">
              <a
                href="tel:+611800930985"
                aria-label="Call us at  +61 (3) 5670-8900"
                className=" ml-2"
              >
                AU +61 1800930985
              </a>
            </p>
          </li>
        </ul>
        <Link to="/" className="logo" smooth={true} offset={0} duration={700}>
          <div className="img">
            <img src={logo} width={30} height={30} alt="logo" />
          </div>
        </Link>
      </nav>
    </div>
  );
};

export default Navbar;
