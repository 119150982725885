import React from "react";
import { Link } from "react-router-dom";
import MyComponent from "../../components/Google";

const Privacy = () => {
  return (
    <div
      className="legal mt-[100px]"
      style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}
    >
      <h1 className=" text-center m-5">Privacy Policy</h1>
      <section>
        <h2>1. Introduction</h2>
        <div className="p-10 flex flex-col gap-2">
          <p className=" text-justify">
            St. KittsTrinity Consulting AG ("we", "our", "us") is committed to
            protecting and respecting your privacy. This Privacy Policy explains
            how we collect, use, disclose, and safeguard your information when
            you visit our website and use our services. Please read this policy
            carefully to understand our views and practices regarding your
            personal data and how we will treat it.
          </p>
        </div>
      </section>
      <section>
        <h2>2. Information We Collect</h2>
        <div className="p-10 flex flex-col gap-2">
          <p className=" text-justify">
            We may collect and process the following data about you:
          </p>
          <div>
            <h3>2.1 Personal Information</h3>
            <ul>
              <li>
                <span className=" underline font-bold mr-1">
                  Contact Information:
                </span>{" "}
                Name, email address, address.
              </li>
              <li>
                <span className=" underline font-bold mr-1">
                  Professional Information:
                </span>{" "}
                Educational background, professional qualifications, and
                employment history.
              </li>
              <li>
                <span className=" underline font-bold mr-1">
                  Identification Information:
                </span>{" "}
                Passport details, visa status, and other identification
                documents.
              </li>
            </ul>
          </div>
          <div>
            <h3>2.2 Usage Data</h3>
            <ul>
              <li>
                Information about how you use our website, including IP address,
                browser type, pages visited, and the date/time of your visits.
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section>
        <h2>3. How We Use Your Information</h2>
        <div className="p-10 flex flex-col gap-2">
          <p className=" text-justify">
            We use the information we collect for various purposes, including:
          </p>
          <div>
            <ul>
              <li>
                To provide, operate, and maintain our website and services.
              </li>
              <li>To process your inquiries and applications.</li>
              <li>
                To communicate with you, including responding to your comments,
                questions, and requests.
              </li>
              <li>
                To improve our website and services by analyzing how users
                interact with our site.
              </li>
              <li>
                To send you updates, marketing communications, and promotional
                materials (with your consent).
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section>
        <h2>4. Legal Basis for Processing</h2>
        <div className="p-10 flex flex-col gap-2 ">
          <p className=" text-justify">
            We process your personal data on the following legal bases:
          </p>
          <div>
            <ul>
              <li>
                <span className=" underline font-bold mr-1">Consent:</span> When
                you have given your explicit consent for a specific purpose.
              </li>
              <li>
                Contractual Necessity: When processing is necessary for the
                performance of a contract with you.
              </li>
              <li>
                <span className=" underline font-bold mr-1">
                  Legal Obligation:{" "}
                </span>{" "}
                When we are legally required to process your data.
              </li>
              <li>
                <span className=" underline font-bold mr-1">
                  Legitimate Interests:
                </span>{" "}
                For our legitimate business interests, provided your rights and
                interests do not override these interests.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section>
        <h2>5. Sharing Your Information</h2>
        <div className="p-10 flex flex-col gap-2 ">
          <p className=" text-justify">We may share your information with:</p>
          <div>
            <ul>
              <li>
                <span className=" underline font-bold mr-1">
                  Service Providers:
                </span>{" "}
                Third parties that perform services on our behalf, such as IT
                and administrative services
              </li>
              <li>
                <span className=" underline font-bold mr-1">
                  Legal Authorities:
                </span>{" "}
                If required to do so by law or in response to valid requests by
                public authorities.
              </li>
              <li>
                <span className=" underline font-bold mr-1">
                  Business Transfers:
                </span>{" "}
                In connection with any merger, sale of company assets,
                financing, or acquisition of all or a portion of our business.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section>
        <h2> 6. Data Security</h2>
        <div className="p-10 flex flex-col gap-2 ">
          <p className=" text-justify">
            We implement appropriate technical and organizational measures to
            protect your personal data against accidental or unlawful
            destruction, loss, alteration, unauthorized disclosure, or access.
          </p>
        </div>
      </section>
      <section>
        <h2> 7. Data Retention</h2>
        <div className="p-10 flex flex-col gap-2 ">
          <p className=" text-justify">
            We will retain your personal data only for as long as necessary to
            fulfill the purposes we collected it for, including for the purposes
            of satisfying any legal, accounting, or reporting requirements.
          </p>
        </div>
      </section>
      <section>
        <h2>8. Your Data Protection Rights</h2>
        <div className="p-10 flex flex-col gap-2 ">
          <p className=" text-justify">
            Depending on your location, you may have the following rights
            regarding your personal data:
          </p>
          <div>
            <ul>
              <li>
                <span className=" underline font-bold mr-1">Access:</span> You
                have the right to request access to your personal data.
              </li>
              <li>
                <span className=" underline font-bold mr-1">Correction:</span>{" "}
                You have the right to request correction of any inaccurate
                personal data we hold about you.
              </li>
              <li>
                <span className=" underline font-bold mr-1">Deletion:</span> You
                have the right to request the deletion of your personal data.
              </li>
              <li>
                <span className=" underline font-bold mr-1">Objection:</span>{" "}
                You have the right to object to the processing of your personal
                data.
              </li>
              <li>
                <span className=" underline font-bold mr-1"> Restriction:</span>{" "}
                You have the right to request restriction of processing your
                personal data.
              </li>
              <li>
                <span className=" underline font-bold mr-1">Portability:</span>{" "}
                You have the right to request the transfer of your personal data
                to another party.
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section>
        <h2> 9. Changes to This Privacy Policy</h2>
        <div className="p-10 flex flex-col gap-2 ">
          <p className=" text-justify">
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on our website.
            You are advised to review this Privacy Policy periodically for any
            changes.
          </p>
        </div>
      </section>
      <section>
        <h2>10. Contact Us</h2>
        <div className="p-10 flex flex-col gap-2 ">
          <p className=" text-justify">
            If you have any questions about this Privacy Policy, please contact
            us:
            <br />
            <br />
            <span className=" underline font-bold mr-1">
              St. KittsTrinity Consulting AG
            </span>
            <br />
            <p className=" text-justify">
              <span className=" underline font-bold mr-1">Address:</span> 26
              Anthipolochagou Georgiou M. Savva Shop 1-2 8201 Paphos Cyprus
            </p>
            <p className=" text-justify">
              <span className=" underline font-bold mr-1">Phone:</span>{" "}
              <a href="tel:+357 80080585">+357 80080585</a>
            </p>
            <p className=" text-justify">
              <span className=" underline font-bold mr-1">Email:</span>{" "}
              <a href="mailto:info@skt-consulting.com">
                info@skt-consulting.com
              </a>
            </p>
          </p>
        </div>
      </section>
      <MyComponent id="google" />
    </div>
  );
};

export default Privacy;
