"use client";
import React from "react";
import "./Footer.css";

import { FaLocationDot } from "react-icons/fa6";
import { Link } from "react-router-dom";
// import AU from "../components/images/australia_flag.svg";
// import cyprus from "../components/images/cyprus.svg";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <ul className="footer-links">
          <li>
            <Link
              to="/"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              smooth={true}
              offset={0}
              duration={700}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/about-us"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              smooth={true}
              offset={10}
              duration={700}
            >
              About
            </Link>
          </li>

          <li>
            <Link
              to="/contact-us"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              smooth={true}
              offset={-100}
              duration={700}
            >
              Contact
            </Link>
          </li>
          <li>
            <Link
              to="/our-network"
              onClick={() => {
                window.scrollTo(0, 0);
              }}
              smooth={true}
              offset={-100}
              duration={700}
            >
              Our Network
            </Link>
          </li>
        </ul>
        <div className=" flex-col w-full gap-5 justify-center items-center ">
          <div className="flex items-center gap-2 w-full justify-center text-1xl mb-3">
            <FaLocationDot />
            {/* <img src={cyprus} width={20} height={20} alt="cyprus" /> */}
            <p>
              26 Anthipolochagou Georgiou M. Savva Shop 1-2 8201 Paphos Cyprus
            </p>
          </div>
          <div className="flex  items-center gap-2 text-1xl w-full justify-center">
            <FaLocationDot />
            {/* <img src={AU} width={20} height={20} alt="AU" /> */}
            <p>245 St Kilda Road VIC 3182 St Kilda, Australia</p>
          </div>
        </div>
        <div className="bottom">
          <span className="line"></span>
        </div>
        <div className="bottom">
          <div className="flex justify-around w-full privacy">
            <Link to="/legal" onClick={() => window.scrollTo(0, 0)}>
              <p className=" sm:mb-2">Legal Notice</p>
            </Link>
            <p className=" sm:mb-2">
              &copy; 2024 SKT Consulting AG.
              <br /> All rights reserved
            </p>
            <Link to="/privacy" onClick={() => window.scrollTo(0, 0)}>
              <p className="mb-2">Privacy Policy</p>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
