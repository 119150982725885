"use client";
import MapContainer from "../components/Google";

import about from "../components/images/about.jpg";
import "./About-us.css";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <div className="about-us mt-[100px]" id="about-us">
      <motion.div
        initial={{ opacity: 0.5, scale: 0.5 }}
        transition={{ duration: 2 }}
        whileInView={{ opacity: 1, scale: 1 }}
        className="container"
      >
        <img src={about} alt="john" />
        <div className="col-2">
          <h2>About Us</h2>
          <span className="line"></span>
        </div>
      </motion.div>
      <div
        id="content"
        className=" mt-10 w-[70vw] justify-center flex items-center"
      >
        <div className="w-full md:justify-center">
          <p className=" text-start text-[1.2rem] md:text-[2rem] mb-5 mt-5">
            Welcome to{" "}
            <span style={{ fontFamily: "aboutFont" }}>
              St. KittsTrinity Consulting AG
            </span>
            , your trusted partner in navigating the journey of migrating to
            Australia as a General Practitioner (GP).
          </p>
          <br />
          <br />
          <h2>Who We Are?</h2>
          <div style={{ margin: "0 auto" }}>
            <p className=" lg:p-10 text-justify text-[1.2rem] md:text-[2rem] mb-5">
              <br /> At{" "}
              <span style={{ fontFamily: "aboutFont" }}>
                St. KittsTrinity Consulting AG
              </span>
              , we specialize in offering tailored guidance and resources for
              GPs who aspire to build their careers in Australia. <br />
              <br />
              Our team comprises experienced professionals with in-depth
              knowledge of the Australian healthcare system, immigration
              procedures, and professional registration processes. <br />
              <br />
              We are dedicated to helping you achieve your career goals and make
              a meaningful contribution to the Australian healthcare community.
              <br />
              <br />
              <strong>Our Vision</strong>, is to be the leading provider of
              expert guidance and support for General Practitioners worldwide,
              ensuring a seamless and successful transition to practice in
              Australia, fostering professional growth, and enhancing the
              healthcare community.
              <br />
              <br />
              <strong>Our Mission</strong> is to empower General Practitioners
              with comprehensive, personalized advice and resources for
              migrating to Australia. We strive to simplify the migration
              process, from visa applications to professional registration and
              employment opportunities, while offering unwavering support in
              navigating cultural and practical challenges. Our commitment is to
              excellence, integrity, and the well-being of our clients, helping
              them to achieve their professional aspirations and contribute to
              the Australian healthcare system.
              <br />
              <br />
            </p>
          </div>
          <h2>What We Offer</h2>
          <div
            style={{ margin: "0 auto" }}
            className=" lg:p-10 text-justify text-[1.2rem] md:text-[2rem] mb-5 mt-5"
          >
            <ul>
              <li className="p-3">
                <strong>Visa and Immigration Assistance:</strong> Expert
                guidance on the types of visas available for medical
                professionals, preparation, and submission of applications, and
                support with documentation.
              </li>
              <li className="p-3">
                <strong>Registration and Licensing:</strong> Assistance with the
                Australian Health Practitioner Regulation Agency (AHPRA)
                registration, credentialing support, and qualification
                verification.
              </li>
              <li className="p-3">
                <strong>Employment Services:</strong> Job search assistance,
                interview preparation, and employer liaison to help you find the
                perfect position.
              </li>
              <li className="p-3">
                <strong>Professional Development:</strong> Continued Medical
                Education (CME) opportunities, networking events, and insights
                into the Australian healthcare system.
              </li>
              <li className="p-3">
                <strong>Legal and Financial Advice:</strong> Access to legal
                consultation regarding contracts and employment laws, and
                financial planning services.
              </li>
            </ul>
          </div>
          <motion.div
            whileHover={{
              boxShadow: " rgba(250, 250, 250, 0.19) 0px 0px 10px 10px",
              scale: 1.1,
            }}
            smooth={true}
            duration={700}
            offset={-100}
            className="w-full flex justify-center"
          >
            <Link
              smooth={true}
              offset={-100}
              duration={700}
              to="/contact-us"
              onClick={() => window.scrollTo(0, 0)}
              className="button lg:w-1/4 md:w-1/2 w-full self-center mb-5 justify-center items-center flex"
            >
              <div smooth={true} duration={700} offset={-100}>
                Contact us
              </div>
            </Link>
          </motion.div>
        </div>
      </div>
      <MapContainer />
    </div>
  );
};

export default AboutUs;
