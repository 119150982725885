import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import styles from "./Login.module.css";
import { FadeLoader } from "react-spinners";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [tfaCode, setTfaCode] = useState("");
  const [error, setError] = useState("");
  const [isTfaRequired, setIsTfaRequired] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/dashboard");
    }
  }, [navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError("");

    try {
      // Step 1: Validate email and password
      const response = await axios.post("/api/auth/login", { email, password });

      if (response.data.token) {
        // If login is successful and no 2FA is required, proceed to dashboard
        localStorage.setItem("token", response.data.token);
        setIsLoading(false);
        navigate("/dashboard");
      } else if (response.data.message === "2FA required") {
        setIsLoading(false);
        // If 2FA is required, prompt for the 2FA code
        setIsTfaRequired(true);
      }
    } catch (err) {
      setIsLoading(false);
      setError(err.response?.data?.error || "Login failed. Please try again.");
    }
  };

  const handleVerifyTfa = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    setError("");

    try {
      // Step 2: Verify the 2FA code
      const response = await axios.post("/api/auth/verify-tfa", {
        email,
        tfaCode,
      });
      localStorage.setItem("token", response.data.token);
      setIsLoading(false);
      navigate("/dashboard"); // Redirect to the dashboard on successful 2FA verification
    } catch (err) {
      setIsLoading(false);
      setError(
        err.response?.data?.error ||
          "2FA verification failed. Please try again."
      );
    }
  };
  if (isLoading) {
    return (
      <div>
        <FadeLoader
          color="#3f4540"
          cssOverride={{
            margin: "300px auto",
          }}
        />
      </div>
    );
  }
  return (
    <div className={styles.container}>
      <h2>Login</h2>
      {!isTfaRequired ? (
        <form onSubmit={handleLogin}>
          <div>
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div>
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          {error && <p className={styles.error}>{error}</p>}
          <button className={styles.login} type="submit">
            Login
          </button>
        </form>
      ) : (
        <form onSubmit={handleVerifyTfa}>
          <div>
            <label>Enter 2FA Code:</label>
            <input
              type="text"
              value={tfaCode}
              onChange={(e) => setTfaCode(e.target.value)}
              required
            />
          </div>
          {error && <p className={styles.error}>{error}</p>}
          <button className={styles.login} type="submit">
            Verify 2FA
          </button>
        </form>
      )}
    </div>
  );
};

export default Login;
